import * as React from 'react'
import AdSense from 'react-adsense'
const AdBlock = () => (
    <div>              
        <AdSense.Google
            client="ca-pub-1335514337857818"
            slot="5490208143"
            style={{ display: 'block' }}
            layout="in-article"
            format="fluid"
        />
    </div>
);
export default AdBlock
